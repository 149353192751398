import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  CrmControlValueAccessorDirective,
  crmControlValueAccessorFactory,
} from 'common-module/core';
import { DateTime, Settings } from 'luxon';
import {
  NzDatePickerModule,
  type SupportTimeOptions,
} from 'ng-zorro-antd/date-picker';
import { debounceTime, startWith, takeUntil } from 'rxjs';

import { getZonedDateTime, getZonedJSDate } from '../../utils/date-time';

@Component({
  standalone: true,
  selector: 'app-date',
  template: `
    <div class="date">
      <nz-date-picker
        style="width: 100%"
        nzSize="large"
        [nzFormat]="format ?? 'MMM dd yyyy'"
        [nzStatus]="status()"
        [nzDisabledDate]="disabledDate"
        [nzPlaceHolder]="placeholder"
        [nzAllowClear]="allowClear ?? false"
        [nzDisabled]="isDisabled()"
        [nzShowTime]="showTime"
        [ngModel]="date"
        (ngModelChange)="dateChanged($event)"
      ></nz-date-picker>
    </div>
  `,
  imports: [NzDatePickerModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [crmControlValueAccessorFactory(DateComponent)],
})
export class DateComponent
  extends CrmControlValueAccessorDirective<DateTime | null>
  implements OnInit
{
  @Input() disabledDate?: (d: Date) => boolean;
  @Input() placeholder = '';
  @Input() format?: string;
  @Input() allowClear?: boolean;
  @Input() showTime?: boolean | SupportTimeOptions = false;

  isDisabled = signal(false);
  date?: Date;

  override ngOnInit() {
    super.ngOnInit();

    this.control.statusChanges
      .pipe(
        debounceTime(0),
        startWith(this.control.status),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => this.isDisabled.set(value === 'DISABLED'));

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.writeValue(value));
  }

  override writeValue(obj: DateTime | null) {
    super.writeValue(obj);

    if (this.value) {
      this.date = getZonedJSDate(this.value);
    } else {
      delete this.date;
    }
  }

  dateChanged(date: Date) {
    this.date = date;

    if (!date) {
      this.change(null);
      return;
    }

    const zone = this.value?.zone ?? Settings.defaultZone;
    this.change(getZonedDateTime(date, zone));
  }
}
